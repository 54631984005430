import {combineReducers, createStore} from 'redux';
import {IUiState, UiReducer} from "./UiState";
import {IPageState, PageReducer} from "./PageState";
import {DashboardReducer, IDashboardState} from "./DashboardState";

export interface IRootState {
    ui: IUiState,
    page: IPageState,
    dashboardUi: IDashboardState,
}

const store = createStore<IRootState, any, any, any>(
    combineReducers({
        ui: UiReducer,
        page: PageReducer,
        dashboardUi: DashboardReducer,
    }));

export default store;