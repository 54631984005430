import React, {Component} from 'react';
import AppContainer from "./components/layouts/AppContainer";
import store from "./store/AppStore";
import {Provider} from "react-redux";
import GMap from "./util/GMap";

class App extends Component {

    componentDidMount(): void {
        window.gmap = new GMap('map');
    }

    render() {
        return (
            <Provider store={store}>
                <AppContainer/>
                <div id='map'></div>
            </Provider>
        );
    }
}

export default App;
