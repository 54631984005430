export interface IPageState {
    showFormDrawer: boolean,
    showFilterDrawer: boolean,
    showFormModal: boolean,
    showDashboardAssetDetail: boolean
}

export enum IPageActionConstants {
    OPEN_FORM_MODAL = 'OPEN_FORM_MODAL',
    CLOSE_FORM_MODAL = 'CLOSE_FORM_MODAL',

    OPEN_FORM_DRAWER = 'OPEN_FORM_DRAWER',
    CLOSE_FORM_DRAWER = 'CLOSE_FORM_DRAWER',
    OPEN_FILTER_DRAWER = 'OPEN_FILTER_DRAWER',
    CLOSE_FILTER_DRAWER = 'CLOSE_FILTER_DRAWER',

    OPEN_DASHBOARD_ASSET_DETAIL_DRAWER = 'OPEN_DASHBOARD_ASSET_DETAIL_DRAWER',
    CLOSE_DASHBOARD_ASSET_DETAIL_DRAWER = 'CLOSE_DASHBOARD_ASSET_DETAIL_DRAWER',

    RESET_STATE_TO_DEFAULT = 'RESET_STATE_TO_DEFAULT',
}

const init: IPageState = {
    showFormDrawer: false,
    showFilterDrawer: false,
    showFormModal: false,
    showDashboardAssetDetail: false
};

export function PageReducer(state: IPageState = init, action: any): IPageState {
    switch (action.type) {
        case IPageActionConstants.OPEN_FORM_DRAWER:
            return {...state, showFormDrawer: true};
        case IPageActionConstants.CLOSE_FORM_DRAWER:
            return {...state, showFormDrawer: false};

        case IPageActionConstants.OPEN_FILTER_DRAWER:
            return {...state, showFilterDrawer: true};
        case IPageActionConstants.CLOSE_FILTER_DRAWER:
            return {...state, showFilterDrawer: false};

        case IPageActionConstants.OPEN_FORM_MODAL:
            return {...state, showFormModal: true};
        case IPageActionConstants.CLOSE_FORM_MODAL:
            return {...state, showFormModal: false};

        case IPageActionConstants.OPEN_DASHBOARD_ASSET_DETAIL_DRAWER:
            return {...state, showDashboardAssetDetail: true};
        case IPageActionConstants.CLOSE_DASHBOARD_ASSET_DETAIL_DRAWER:
            return {...state, showDashboardAssetDetail: false};

        case IPageActionConstants.RESET_STATE_TO_DEFAULT:
            return {...state, ...init};
        default:
            return state;
    }
}

export class PageAction {
    openFormDrawer = () => {
        return {type: IPageActionConstants.OPEN_FORM_DRAWER}
    };

    closeFormDrawer = () => {
        return {type: IPageActionConstants.CLOSE_FORM_DRAWER}
    };

    openDashboardAssetDetailDrawer = () => {
        return {type: IPageActionConstants.OPEN_DASHBOARD_ASSET_DETAIL_DRAWER}
    };

    closeDashboardAssetDetailDrawer = () => {
        return {type: IPageActionConstants.CLOSE_DASHBOARD_ASSET_DETAIL_DRAWER}
    };

    openFilterDrawer = () => {
        return {type: IPageActionConstants.OPEN_FILTER_DRAWER}
    };

    closeFilterDrawer = () => {
        return {type: IPageActionConstants.CLOSE_FILTER_DRAWER}
    };

    openFormModal = () => {
        return {type: IPageActionConstants.OPEN_FORM_MODAL}
    };

    closeFormModal = () => {
        return {type: IPageActionConstants.CLOSE_FORM_MODAL}
    };

    resetStateToDefault = () => {
        return {type: IPageActionConstants.RESET_STATE_TO_DEFAULT}
    }
}