import React, {Component} from 'react';
import {Spinner} from "reactstrap";

interface IProps {
    show: boolean
}

class PageLoading extends Component <IProps, {}> {
    static defaultProps = {
        show: false
    };

    render() {
        if (!this.props.show) {
            return '';
        }

        return (
            <div className="page-loader">
                <div className="loading-content text-center">
                    <Spinner color="primary" />
                </div>
            </div>
        );
    }
}

export default PageLoading;
