export default {
    DEFAULT_PAGE_SIZE:10,
    DEFAULT_PAGE_NO:1,
    DEFAULT_LOCATION_FRACTION : 8,
    DEFAULT_REFRESH_INTERVAL : 30000,
    DEFAULT_DATE_FORMAT : "YYYY-MM-DD HH:mm:ss",
    SERVER_DATE_FORMAT : "YYYY-MM-DD[T]HH:mm:ss",
    AM_PM_FORMAT : "YYYY-MM-DD hh:mm:ss A",
    TIME_FORMAT : "HH:mm:ss",
    ONLY_DATE_FORMAT : "YYYY-MM-DD",
    IMAGES: {
        ARROW_LEFT: process.env.PUBLIC_URL + '/images/arrow-left.png',
        ARROW_RIGHT: process.env.PUBLIC_URL + '/images/arrow-right.png',
        PATCH_MAP: process.env.PUBLIC_URL + '/images/patchmap_legend.png',
    },
    ROLES: {
        SUPERADMIN: "SUPERADMIN",
        RESELLER: "RESELLER",
        ADMIN: "ADMIN",
        EMPLOYEE: "EMPLOYEE",
    },
    INTERVAL: {
        DAYS: 1,
        WEEKS: 2,
        MONTHS: 3,
        YEARS: 4,
    },
    ALERT_TYPES:{
        DIGITAL_INPUT:'p5',
        SPEED_EXCEED_GEO_FENCE:'o0',
        SPEED_EXCEED_GEO_GLOBAL:'No',
        SPEED_EXCEED_GEO_ROAD:'Xy',

        GEOFENCE_ENTRY:'10',
        GEOFENCE_ENTRY_OR_EXIT:'w5',
        GEOFENCE_EXIT:'3N',

        AFTER_HOURS_USAGE:'Om',
        UNKNOWN_ASSET_REPORT:'zy',
        EMERGENCY_ALERT:'MD',
        EMERGENCY_ACKNOWLEDGED: 'kY',
        POWER_DISCONNECTED:'6o',
        UNEXPECTED_MOVEMENT:'lM'
    },
    MEDIA:{
        SMALL:425,
        MEDIUM:768,
        LARGE:1024
    },
    DIRECTION_ICON:'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAABGdBTUEAALGPC/xhBQAAACBjSFJN\
                        AAB6JQAAgIMAAPn/AACA6QAAdTAAAOpgAAA6mAAAF2+SX8VGAAACFlBMVEX///8AAAAKCgoAAAAA\
                        AAAQEBArKysAAAAAAAAWFhY5OTkAAAAAAABAQEBDQ0MAAAA+Pj5JSUkAAABJSUlEREQAAAAAAABC\
                        QkJJSUkAAAAAAABISEhDQ0MAAABBQUFISEgAAAAAAABISEhEREQAAAAAAABCQkJJSUkAAABISEhE\
                        REQAAAAAAABJSUkAAABHR0dDQ0MAAABJSUk7OzsAAABJSUlFRUUAAAAAAABJSUkAAAASEhIEBAQA\
                        AAAAAABGRkZDQ0MAAAAZGRkZGRkTExMAAAAAAAAAAAA6OjpEREQAAAAAAAAREREZGRkZGRkZGRkA\
                        AAAAAAA6Ojo6OjoLCwsXFxcZGRkAAAAAAAAAAAAGBgYyMjIAAAAAAAABAQEREREAAAAAAAAPDw8h\
                        ISEDNQAefxgKpgAiyhcEPAAMvwAf1xM8ijYJkQAMxwAZ1g00zCoLswAMzAAU1Qco2Rw8gjcMwwAN\
                        0QAQ1QMd1xE0zyoLuAAN0wAO1AEV1Qg8hjcJlwAN1AAR1QQc1xA00SoDLgA7dzcJjgACKQAW1gk8\
                        fjcKnwA02ylBQUELuQANzQAT1QYP1AIN0gANzgA8jjcKpAAMyAAY1gwb1w8Wlg0FDAQAAAAHdgAM\
                        xAAN0AAz2yhCQkICKgAMwQAchxUXFxcGXwA3eTMKpQANzAEWRhMYGBgCHwAMugAn1BsDLAABDwAm\
                        bCIlJSX///9o8X+ZAAAAZHRSTlMAPFABsNAFPfX6XAO5zwxI+WS2wglA7vZapf66DUv4ZQitvxlS\
                        8vtfprMSRuxXo7kU+V4BDMQUQvpNB9iGf869CzfMbywmZ8P+Swau/KJHHhuXxwuLNA0KLH7kYY6G\
                        JQYFHXaUtuHAVgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQfkAwIU\
                        Gju2oVk8AAABNklEQVQoz2NgIAIwMmEXZ2ZhZcMqwc7ByYVNnJsnJZWXD4sEP0dauoAgFhuEMjKz\
                        soVFMCRExXJy8/LFJTAkJKUKCouKS6Rl0MRlxXJKy8orKuXkUcUVFFOqCqtraovrlJRRJFRU60vL\
                        qhsam5rV1FE0aLQANTQ0ALW0amohSWjrtJWWNQBBY0Wzmi6SBr12sAaglo7iTn0DhJMMu0rLampq\
                        qqury5u6e4yMoeImiim9feX9/RMmTppU1l882dQMKmFuMWVq47TpM2bOmj1n6txp8+ZbQsSt9BYs\
                        bGxatNjaxtZu1pLSSVlL7R3AEo4cy5avWOnk7MLA4OrmPmvV6jUeniBxL++163x8/fwh2gNEAzlm\
                        rQ8KBjlplk5IaBjC7eERkVEbooGMmNi4eNSAS0hMSmbACQCl5lyn1WUQ1wAAACV0RVh0ZGF0ZTpj\
                        cmVhdGUAMjAyMC0wMy0wMlQyMDoyNjo1OSswMzowMFO7LEoAAAAldEVYdGRhdGU6bW9kaWZ5ADIw\
                        MjAtMDMtMDJUMjA6MjY6NTkrMDM6MDAi5pT2AAAAAElFTkSuQmCC',
    LEAFLET_MARKER: process.env.PUBLIC_URL + "/images/leflet-marker-blue.png",
    LEAFLET_MARKER_SHADOW: process.env.PUBLIC_URL + "/images/leaflet-marker-shadow.png",
};
