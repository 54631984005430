export interface IDashboardState {
    isPatchActive: boolean,
    isPoiActive: boolean,
    isZoneActive: boolean,
    isAtsActive: boolean,
    showAssetInfo: boolean,
    selectedAsset: any,
    showAssetDirectDetail: boolean,
    popupWindowName: any,
}

export enum DashboardConstants {
    TOGGLE_PATCH = 'TOGGLE_PATCH',
    TOGGLE_ZONE = 'TOGGLE_ZONE',
    TOGGLE_POI = 'TOGGLE_POI',

    ACTIVE_ZONE = 'ACTIVE_ZONE',
    INACTIVE_ZONE = 'INACTIVE_ZONE',

    INACTIVE_PATCH = 'HIDE_PATCH',
    ACTIVE_PATCH = 'SHOW_PATCH',

    HIDE_POI = 'HIDE_POI',
    SHOW_POI = 'SHOW_POI',

    ACTIVE_ATS = 'ACTIVE_ATS',
    INACTIVE_ATS = 'INACTIVE_ATS',

    SET_ASSET_INFO = 'SET_ASSET_INFO',
    SET_SHOW_ASSET_INFO = 'SET_SHOW_ASSET_INFO',
    SET_SHOW_ASSET_DIRECT_DETAIL = 'SET_SHOW_ASSET_DIRECT_DETAIL',
    SET_POPUP_WINDOW_NAME = 'SET_POPUP_WINDOW_NAME',
}


const init: IDashboardState = {
    isPatchActive: false,
    isPoiActive: false,
    isZoneActive: false,
    isAtsActive: false,
    showAssetInfo: false,
    selectedAsset: null,
    showAssetDirectDetail: false,
    popupWindowName: null,
};

export function DashboardReducer(state: IDashboardState = init, action: any): IDashboardState {
    switch (action.type) {
        case DashboardConstants.TOGGLE_PATCH:
            const isPatchEnabled = !state.isPatchActive;
            return {...state, isPatchActive: isPatchEnabled};
        case DashboardConstants.TOGGLE_ZONE:
            const isZonEnabled = !state.isZoneActive;
            return {...state, isZoneActive: isZonEnabled};

        case DashboardConstants.TOGGLE_POI:
            const isPoiEnabled = !state.isPoiActive;
            return {...state, isPoiActive: isPoiEnabled};

        case DashboardConstants.ACTIVE_PATCH:
            return {...state, isPatchActive : true};
        case DashboardConstants.INACTIVE_PATCH:
            return {...state, isPatchActive : false};

        case DashboardConstants.SHOW_POI:
            return {...state, isPoiActive : true};
        case DashboardConstants.HIDE_POI:
            return {...state, isPoiActive : false};

        case DashboardConstants.ACTIVE_ZONE:
            return {...state, isZoneActive : true};
        case DashboardConstants.INACTIVE_ZONE:
            return {...state, isZoneActive : false};

        case DashboardConstants.ACTIVE_ATS:
            return {...state, isAtsActive : true};
        case DashboardConstants.INACTIVE_ATS:
            return {...state, isAtsActive : false};

        case DashboardConstants.SET_ASSET_INFO:
            return {...state, selectedAsset: action.value};
        case DashboardConstants.SET_SHOW_ASSET_INFO:
            return {...state, showAssetInfo: action.value};
        case DashboardConstants.SET_SHOW_ASSET_DIRECT_DETAIL:
            return {...state, showAssetDirectDetail: action.value};
        case DashboardConstants.SET_POPUP_WINDOW_NAME:
            return {...state, popupWindowName: action.value};

        default:
            return state;
    }
}

export  class DashboardAction {
    togglePatch = () => {
        return {type: DashboardConstants.TOGGLE_PATCH}
    };

    togglePoi = () => {
        return {type: DashboardConstants.TOGGLE_POI}
    };

    toggleZone = () => {
        return {type: DashboardConstants.TOGGLE_ZONE}
    };

    inActivePatch = () => {
        return {type: DashboardConstants.INACTIVE_PATCH}
    };

    activePatch = () => {
        return {type: DashboardConstants.ACTIVE_PATCH}
    };

    inActivePoi = () => {
        return {type: DashboardConstants.HIDE_POI}
    };

    activePoi = () => {
        return {type: DashboardConstants.SHOW_POI}
    };

    inActiveZone = () => {
        return {type: DashboardConstants.INACTIVE_ZONE}
    };

    activeZone = () => {
        return {type: DashboardConstants.ACTIVE_ZONE}
    };

    inActiveAts = () => {
        return {type: DashboardConstants.INACTIVE_ATS}
    };

    activeAts = () => {
        return {type: DashboardConstants.ACTIVE_ATS}
    };

    setAssetInfo = (asset: any) => {
        return {type: DashboardConstants.SET_ASSET_INFO, value: asset};
    };

    setShowAssetInfo = (value: boolean) => {
        return {type: DashboardConstants.SET_SHOW_ASSET_INFO, value: value};
    };

    setShowAssetDirectDetail = (value: boolean) => {
        return {type: DashboardConstants.SET_SHOW_ASSET_DIRECT_DETAIL, value: value};
    };

    setPopupWindowName = (value: any) => {
        return {type: DashboardConstants.SET_POPUP_WINDOW_NAME, value: value};
    };
}
