import {IDimension, ILocation} from "./GMapTypes"
import {guid} from "./Helpers";
import Auth from "./Auth";

declare global {
    interface Window {
        map: any;
        google: any
        gmap: GMap,
        currentPath: string
    }
}

export default class GMap {
    map: any = null;
    auth: Auth = new Auth();
    dashboardLocationMarker: any = null;
    drawingManager: any = null;

    googleObjects: any = {
        polygons: [],
        circles: [],
        markers: [],
        marker1: [],
        marker2: [],
        markerClusters: [],
        polyLine: [],
        InfoWindow: {},
        poiInfoWindow: {},
        mapLabels: [],
        patchMap: {},
        measureTool: {},
        atsMapLabels: []
    };

    defaultLocation: ILocation = {
        lat: 39.305,
        lng: -76.617
    };

    constructor(id: string) {
        this.map = new (GMap.getGoogle()).maps.Map(document.getElementById(id), {
            center: this.defaultLocation,
            zoom: 6,
            minZoom: 4
        });

        /* Add Default listener */
        GMap.getGoogle().maps.event.addListener(this.map, 'zoom_changed', () => {
            //console.log("zoom -",this.map.getZoom());
            window.localStorage.setItem("mapZoom", this.map.getZoom());
        });
    }

    clearDashboardLocationMarker() {
        if (this.dashboardLocationMarker) {
            this.dashboardLocationMarker.setMap(null);
            this.dashboardLocationMarker =  null;
        }
    }

    addGoogleObject(type: any, value: any) {
        let objectUuid = '';
        switch (type) {
            case 'polygon':
                objectUuid = this.addUpdatePolygon(value);
                break;
            case 'circle':
                objectUuid = this.addUpdateCircle(value);
                break;
            case 'marker':
                objectUuid = this.addUpdateMarker(value);
                break;
            case 'markerCluster':
                objectUuid = this.addUpdateMarkerCluster(value);
                break;
            case 'polyLine':
                this.addUpdatepolyLine(value);
                break;
        }
        return objectUuid;
    }

    addUpdatePolygon(value: any) {
        let objectUuid = value.uuid;
        if (value.uuid !== undefined) {
            const foundIndex = this.googleObjects.polygons.findIndex((item: any) => item.uuid === value.uuid);
            this.googleObjects.polygons[foundIndex] = { uuid: value.uuid, data: value };
        } else {
            const uuid = guid();
            value['uuid'] = uuid;
            this.googleObjects.polygons[this.googleObjects.polygons.length] = { uuid: uuid, data: value };
            objectUuid = value['uuid'];
        }
        return objectUuid;
    }

    addUpdatePolyLines(value: any, key: string) {
        let objectUuid = value.uuid;
        if (objectUuid !== undefined) {
            const foundIndex = this.googleObjects[key].findIndex((item: any) => item.uuid === value.uuid);
            this.googleObjects[key][foundIndex] = { uuid: value.uuid, data: value };
        } else {
            const uuid = guid();
            value['uuid'] = uuid;
            this.googleObjects[key][this.googleObjects[key].length] = { uuid: uuid, data: value };
            objectUuid = value['uuid'];
        }
        return objectUuid;
    }

    addUpdateCircle(value: any) {
        let objectUuid = value.uuid;
        if (objectUuid !== undefined) {
            const foundIndex = this.googleObjects.circles.findIndex((item: any) => item.uuid === value.uuid);
            this.googleObjects.circles[foundIndex] = { uuid: value.uuid, data: value };
        } else {
            const uuid = guid();
            value['uuid'] = uuid;
            this.googleObjects.circles[this.googleObjects.circles.length] = { uuid: uuid, data: value };
            objectUuid = value['uuid'];
        }
        return objectUuid;
    }

    addUpdateMarker(value: any) {
        let objectUuid = value.uuid;
        if (value.uuid !== undefined) {
            const foundIndex = this.googleObjects.markers.findIndex((item: any) => item.uuid === value.uuid);
            this.googleObjects.markers[foundIndex] = { uuid: value.uuid, data: value };
        } else {
            const uuid = guid();
            value['uuid'] = uuid;
            this.googleObjects.markers[this.googleObjects.markers.length] = { uuid: uuid, data: value };
            objectUuid = value.uuid;
        }
        return objectUuid;
    }

    addUpdateMarkerType(value: any, markerType: string) {
        markerType = (markerType === "marker") ? "markers" : markerType;
        let objectUuid = value.uuid;
        if (value.uuid !== undefined) {
            const foundIndex = this.googleObjects[markerType].findIndex((item: any) => item.uuid === value.uuid);
            this.googleObjects[markerType][foundIndex] = { uuid: value.uuid, data: value };
        } else {
            const uuid = guid();
            value['uuid'] = uuid;
            this.googleObjects[markerType][this.googleObjects[markerType].length] = { uuid: uuid, data: value };
            objectUuid = value['uuid'];
        }
        return objectUuid;
    }

    addUpdateMarkerCluster(value: any) {
        let objectUuid = value.uuid;
        if (value.uuid !== undefined) {
            const foundIndex = this.googleObjects.markerClusters.findIndex((item: any) => item.uuid === value.uuid);
            this.googleObjects.markerClusters[foundIndex] = { uuid: value.uuid, data: value };
        } else {
            const uuid = guid();
            value['uuid'] = uuid;
            this.googleObjects.markerClusters[this.googleObjects.markerClusters.length] = { uuid: uuid, data: value };
            objectUuid = value['uuid'];
        }
        return objectUuid;
    }

    addUpdatepolyLine(value: any) {
        if (value.uuid !== undefined) {
            const foundIndex = this.googleObjects.polyLine.findIndex((item: any) => item.uuid === value.uuid);
            this.googleObjects.polyLine[foundIndex] = { uuid: value.uuid, data: value };
        } else {
            const uuid = guid();
            value['uuid'] = uuid;
            this.googleObjects.polyLine[this.googleObjects.polyLine.length] = { uuid: uuid, data: value };
        }
    }

    remove(type: string, uuid: string) {
        switch (type) {
            case 'polygon':
                const foundPL = this.googleObjects.polygons.findIndex((item: any) => item.uuid === uuid);

                if (foundPL >= 0) {
                    this.googleObjects.polygons[foundPL]['data'].setMap(null);
                    this.googleObjects.polygons = this.googleObjects.polygons.filter((item: any) => item.uuid !== uuid);
                }

                break;
            case 'circle':
                const foundCI = this.googleObjects.circles.findIndex((item: any) => item.uuid === uuid);

                if (foundCI >= 0) {
                    this.googleObjects.circles[foundCI]['data'].setMap(null);
                    this.googleObjects.circles = this.googleObjects.circles.filter((item: any) => item.uuid !== uuid);
                }

                break;
            case 'markerCluster':
                const foundMc = this.googleObjects.markerClusters.findIndex((item: any) => item.uuid === uuid);

                if (foundMc >= 0) {
                    this.googleObjects.markerClusters[foundMc]['data'].clearMarkers();
                    this.googleObjects.markerClusters = this.googleObjects.markerClusters.filter((item: any) => item.uuid !== uuid);
                }
                break;
        }

        //There is multiple type of markers needed, for now this is the solution
        //the whole code of adding object and remove require refactor
        type = (type === "marker") ? "markers" : type;
        if (type === 'markers' || type === 'marker1' || type === 'marker2') {
            const foundMK = this.googleObjects[type].findIndex((item: any) => item.uuid === uuid);
            if (foundMK >= 0) {
                this.googleObjects[type][foundMK]['data'].setPosition(null);
                this.googleObjects[type] = this.googleObjects[type].filter((item: any) => item.uuid !== uuid);
            }
        }

        if (type === 'polyLine') {
            const foundPl = this.googleObjects[type].findIndex((item: any) => item.uuid === uuid);

            if (foundPl >= 0) {
                this.googleObjects[type][foundPl]['data'].setMap(null);
                this.googleObjects[type] = this.googleObjects[type].filter((item: any) => item.uuid !== uuid);
            }
        }
    }

    removeAll(type?: string) {
        this.clearDashboardLocationMarker();
        switch (type) {
            case 'polygon':
                this.googleObjects.polygons.forEach((item: any) => {
                    this.remove('polygon', item.uuid);
                });
                break;
            case 'circle':
                this.googleObjects.circles.forEach((item: any) => {
                    this.remove('circle', item.uuid);
                });
                break;
            case 'markerCluster':
                this.googleObjects.markerClusters.forEach((item: any) => {
                    this.remove('markerCluster', item.uuid);
                });
                break;
            case 'markers':
                this.googleObjects.markers.forEach((item: any) => {
                    this.remove('markers', item.uuid);
                });
                break;
            case 'polyLine':
                this.googleObjects.polyLine.map((item: any) => this.remove('polyLine', item.uuid));
                break;
            case 'marker':
                break;
            case 'marker1':
                break;
            case 'marker2':
                break;
            default:
                this.googleObjects.polygons.map((item: any) => this.remove('polygon', item.uuid));
                this.googleObjects.circles.map((item: any) => this.remove('circle', item.uuid));
                this.googleObjects.markers.map((item: any) => this.remove('marker', item.uuid));
                this.googleObjects.marker1.map((item: any) => this.remove('marker1', item.uuid));
                this.googleObjects.marker2.map((item: any) => this.remove('marker2', item.uuid));
                this.googleObjects.markerClusters.map((item: any) => this.remove('markerCluster', item.uuid));
                this.googleObjects.polyLine.map((item: any) => this.remove('polyLine', item.uuid));
                break;
        }

        //There is multiple type of markers needed, for now this is the solution
        //the whole code of adding object and remove require refactor
        type = (type === "marker") ? "markers" : type;
        if (type === 'markers' || type === 'marker1' || type === 'marker2' || type === 'polyLine') {
            this.googleObjects[type].map((item: any) => this.remove(type || "", item.uuid));
        }
    }

    removeAllByUuid(type?: string, objectUuids: any = []) {
        if (!objectUuids) {
            return;
        }
        switch (type) {
            case 'polygon':
                this.googleObjects.polygons.forEach((item: any) => {
                    objectUuids.find((uuid: any) => uuid === item.uuid) && this.remove('polygon', item.uuid);
                });
                break;
            case 'circle':
                this.googleObjects.circles.forEach((item: any) => {
                    objectUuids.find((uuid: any) => uuid === item.uuid) && this.remove('circle', item.uuid);
                });
                break;
            case 'markerCluster':
                this.googleObjects.markerClusters.forEach((item: any) => {
                    objectUuids.find((uuid: any) => uuid === item.uuid) && this.remove('markerCluster', item.uuid);
                });
                break;
            case 'markers':
                this.googleObjects.markers.forEach((item: any) => {
                    objectUuids.find((uuid: any) => uuid === item.uuid) && this.remove('markers', item.uuid);
                });
                break;
            case 'polyLine':
                break;
            case 'marker':
                break;
            case 'marker1':
                break;
            case 'marker2':
                break;
            default:
                this.googleObjects.polygons.forEach((item: any) => {
                    objectUuids.find((uuid: any) => uuid === item.uuid) && this.remove('polygon', item.uuid)
                });
                this.googleObjects.circles.forEach((item: any) => {
                    objectUuids.find((uuid: any) => uuid === item.uuid) && this.remove('circle', item.uuid)
                });
                this.googleObjects.markers.forEach((item: any) => {
                    objectUuids.find((uuid: any) => uuid === item.uuid) && this.remove('marker', item.uuid)
                });
                this.googleObjects.marker1.forEach((item: any) => {
                    objectUuids.find((uuid: any) => uuid === item.uuid) && this.remove('marker1', item.uuid)
                });
                this.googleObjects.marker2.forEach((item: any) => {
                    objectUuids.find((uuid: any) => uuid === item.uuid) && this.remove('marker2', item.uuid)
                });
                this.googleObjects.markerClusters.forEach((item: any) => {
                    objectUuids.find((uuid: any) => uuid === item.uuid) && this.remove('markerCluster', item.uuid)
                });
                break;
        }

        //There is multiple type of markers needed, for now this is the solution
        //the whole code of adding object and remove require refactor
        type = (type === "marker") ? "markers" : type;
        if (type === 'markers' || type === 'marker1' || type === 'marker2' || type === 'polyLine') {
            this.googleObjects[type].map((item: any) => this.remove(type || "", item.uuid));
        }
    }

    isLocationInsideCircle(uuid: string, location: any): boolean {
        const foundCI = this.googleObjects.circles.findIndex((item: any) => item.uuid === uuid);

        if (foundCI >= 0 && (location !== null && location.hasOwnProperty('lat') && location.hasOwnProperty('lng'))) {
            return this.googleObjects.circles[foundCI]['data'].getBounds().contains(location);
        }

        return false;
    }

    isLocationInsidePolygon(uuid: string, location: any): boolean {
        const foundPO = this.googleObjects.polygons.findIndex((item: any) => item.uuid === uuid);

        if (foundPO >= 0 && (location !== null && location.hasOwnProperty('lat') && location.hasOwnProperty('lng'))) {
            const path = this.googleObjects.polygons[foundPO]['data'].getPath();
            const coordinates = [];

            for (let i = 0; i < path.length; i++) {
                coordinates.push({ lat: path.getAt(i).lat(), lng: path.getAt(i).lng() });
            }

            const bermudaTriangle = new (GMap.getGoogle()).maps.Polygon({ paths: coordinates });

            const coordinate = new (GMap.getGoogle()).maps.LatLng(location);

            return (GMap.getGoogle()).maps.geometry.poly.containsLocation(coordinate, bermudaTriangle);
        }

        return false;
    }

    /**----------------**
     * Map methods
     **----------------**/
    setCenter(center: ILocation) {
        this.map.setCenter(center);
    }

    setZoom(zoom: number) {
        //console.log("set zoom-", zoom);
        this.map.setZoom(zoom);
    }

    setDimension(dimension: IDimension) {
        const node = this.map.getDiv();

        node.style.width = dimension.width;

        node.style.height = dimension.height;
    }

    loadMap(id: string) {
        const node = this.map.getDiv();

        const domElement: HTMLElement | null = document.getElementById(id);

        if (domElement !== null) {
            domElement.appendChild(node);
            this.clearDrawingManager();
            this.removeAll();
        }
    }

    setCenterByLocation() {
        navigator.geolocation.getCurrentPosition((position) => {
            const location = GMap.getLocation(position.coords.latitude, position.coords.longitude);
            this.map.setCenter(location);
        }, () => {
            const cacheLocation = (this.auth.getMapLocation() && JSON.parse(this.auth.getMapLocation())) || this.defaultLocation;
            this.map.setCenter(cacheLocation);
        });
    }

    static getLocation(lat: any, lng: any): any {
        return (new window.google.maps.LatLng(lat, lng));
    }

    getMap(): any {
        return this.map;
    }

    setDrawingManager(configs: any): any {
        this.clearDrawingManager();

        this.drawingManager = new (GMap.getGoogle()).maps.drawing.DrawingManager(configs);

        return this.drawingManager;
    }

    getDrawingManager(): any {
        return this.drawingManager;
    }

    clearDrawingManager(): void {
        if (this.drawingManager !== null) {
            this.drawingManager.setMap(null);
        }
    }

    addSearchBox() {
        const map = GMap.getGoogle();
        const input = document.getElementById('pac-input');

        if (input) {
            const searchBox = new map.maps.places.SearchBox(input);
            this.getMap().controls[map.maps.ControlPosition.TOP_CENTER].push(input);
            map.maps.event.addListener(searchBox, 'places_changed', () => {
                searchBox.set('map', null);
                const places = searchBox.getPlaces();
                places.forEach((place: any) => {
                    this.setCenter({
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng(),
                    });
                    this.setZoom(15);
                });
            });

            setTimeout(() => {
                if (input)
                    input.style.display = 'block';
            }, 2000);

            return searchBox;
        }
        return null;
    }

    static hideSearchBox() {
        const input = document.getElementById('pac-input');

        if (input)
            input.style.display = 'none';
    }

    static getGoogle() {
        return window.google;
    }

    addInfoWindow(data: any, type: any) {
        if (type === "infowindow") {
            this.googleObjects.InfoWindow = data;
        }
        if (type === "poiInfoWindow") {
            this.googleObjects.poiInfoWindow = data;
        }
        if (type === "mapLabels") {
            this.googleObjects.mapLabels = data;
        }
        if (type === "patchMap") {
            this.googleObjects.patchMap = data;
        }
        if (type === "measureTool") {
            this.googleObjects.measureTool = data;
        }
        if (type === "atsMapLabels") {
            this.googleObjects.atsMapLabels = data;
        }
    }
    removeInfoWindow(type: any) {
        if (type === "infowindow") {
            this.googleObjects.InfoWindow && Object.keys(this.googleObjects.InfoWindow).length > 0 && this.googleObjects.InfoWindow.open(null);
        }
        if (type === "poiInfoWindow") {
            this.googleObjects.poiInfoWindow && Object.keys(this.googleObjects.poiInfoWindow).length > 0 && this.googleObjects.poiInfoWindow.open(null);
        }
        if ((type === "patchMap") && (this.googleObjects.patchMap && Object.keys(this.googleObjects.patchMap).length > 0)) {
            this.googleObjects.patchMap.setMap(null);
        }
        if ((type === "mapLabels") && this.googleObjects.mapLabels.length > 0) {
            this.googleObjects.mapLabels.map((item: any) => {
                item.setMap(null);
                return item;
            });
            this.googleObjects.mapLabels = [];
        }
        if ((type === "atsMapLabels") && this.googleObjects.atsMapLabels.length > 0) {
            this.googleObjects.atsMapLabels.map((item: any) => {
                item.setMap(null);
                return item;
            });
            this.googleObjects.mapLabels = [];
        }
        if (type === "measureTool" && Object.keys(this.googleObjects.measureTool).length > 0) {
            this.googleObjects.measureTool.end();
        }
    }
}
